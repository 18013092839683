<template>
  <EntitySingle
    :config="config"
    :entity.sync="entity"
    :loaded.sync="entityLoaded"
    :edit-mode="false"
  >
    <template v-if="entityLoaded">
      <!-- First Row -->
      <b-row class="match-height">
        <b-col
          cols="12"
          xl="9"
          lg="8"
          md="7"
        >
          <!-- :card-hits-list="timelineList" -->
          <card-holders-info-card
            :entity="entity"
            :endpoint="config.endpoint"
            :edit-path="`${config.route}-edit`"
          />
        </b-col>
        <b-col
          cols="12"
          md="5"
          xl="3"
          lg="4"
        >
          <card-holders-plan-card :entity="entity" />
        </b-col>
      </b-row>

      <b-row>
        <b-col
          cols="12"
          lg="3"
        >
          <card-holders-timeline-card :items-list="cardHitSummaryList" />
        </b-col>
        <b-col
          cols="12"
          lg="9"
        >
          <chartjs-bar-chart />
        </b-col>
      </b-row>
    </template>
  </EntitySingle>
</template>

<script>
import {
  BRow, BCol,
} from 'bootstrap-vue'
// import axios from '@/libs/axios'
// import ChartjsBarChart from '@/views/charts-and-maps/charts/chartjs/ChartjsBarChart.vue'
import EntitySingle from '@/layouts/entity/EntitySingle.vue'
import CardHoldersInfoCard from './CongressesInfoCard.vue'
import CardHoldersPlanCard from './CongressesPlanCard.vue'
import CardHoldersTimelineCard from './CongressesTimelineCard.vue'
import storeModule from '../CongressStoreModule'

export default {
  components: {
    BRow,
    BCol,
    // ChartjsBarChart,
    EntitySingle,

    // Local Components
    CardHoldersInfoCard,
    CardHoldersPlanCard,
    CardHoldersTimelineCard,
  },
  data() {
    return {
      entity: {},
      entityLoaded: false,
    }
  },
  computed: {
    config() {
      return {
        store: storeModule,
        module: 'congresses',
        endpoint: 'congresses',
        route: 'congresses',
        title: {
          single: this.$t('Congress Add'),
          plural: this.$t('Congresses'),
        },
      }
    },
  },
}
</script>

<template>
  <b-card title="User Timeline">
    <app-timeline>
      <app-timeline-item
        variant="info"
        title="Central Gate Access"
        subtitle="By card"
        time="12 min ago"
      />
      <app-timeline-item
        variant="warning"
        title="Shift End"
        subtitle="8 hours total"
        time="45 min ago"
      />
      <app-timeline-item
        variant="info"
        title="Side Gate Access"
        subtitle="By smartphone"
        time="2 days ago"
      />
      <app-timeline-item
        variant="warning"
        title="Shift Start"
        subtitle="accounting"
        time="3 days ago"
      />
      <!-- v-for="item in itemsList"
        :key="item.id" -->
    </app-timeline>
  </b-card>
</template>

<script>
import {
  BCard,
} from 'bootstrap-vue'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'

export default {
  components: {
    BCard,
    AppTimeline,
    AppTimelineItem,
  },
  // props: {
  //   itemsList: {
  //     type: Array,
  //     required: true,
  //   },
  // },
}
</script>

<style>
</style>

<template>
  <b-card>
    <b-row>
      <!-- User Info: Left col -->
      <b-col
        cols="21"
        xl="6"
        class="d-flex justify-content-between flex-column"
      >
        <!-- User Avatar & Action Buttons -->
        <div class="d-flex justify-content-start">
          <b-avatar
            :src="entity.avatar"
            :text="avatarText(entity.name)"
            size="104px"
            rounded
          />
          <div class="d-flex flex-column ml-1">
            <div class="mb-1">
              <h4 class="mb-0">
                {{ entity.name }}
              </h4>
              <span class="card-text">{{ entity.email }}</span>
            </div>
            <div class="d-flex flex-wrap">
              <b-button
                :to="{ name: editPath, params: { id: entity.id } }"
                variant="primary"
              >
                {{ $t('Edit') }}
              </b-button>
              <b-button
                variant="outline-danger"
                class="ml-1"
                @click="handleDeleteClick(entity)"
              >
                {{ $t('Delete') }}
              </b-button>
            </div>
          </div>
        </div>

        <!-- User Stats -->
        <div class="d-flex align-items-center mt-2">
          <div class="d-flex align-items-center mr-2">
            <b-avatar variant="light-primary" rounded>
              <feather-icon icon="ShieldIcon" size="18" />
            </b-avatar>
          </div>
        </div>
      </b-col>

      <!-- Right Col: Table -->
      <b-col cols="12" xl="6">
        <table class="mt-2 mt-xl-0 w-100">
          <tr>
            <th class="pb-50">
              <feather-icon icon="UserIcon" class="mr-75" />
              <span class="font-weight-bold">{{
                $t('Company Department')
              }}</span>
            </th>
            <td class="pb-50">
              {{ entity.companyDepartmentName }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon icon="UsersIcon" class="mr-75" />
              <span class="font-weight-bold">{{
                $t('Company Sub Department')
              }}</span>
            </th>
            <td class="pb-50">
              {{ entity.companyDepartmentGroupName }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon icon="CheckIcon" class="mr-75" />
              <span class="font-weight-bold">{{
                $t('Card Holder Job Modal')
              }}</span>
            </th>
            <td class="pb-50 text-capitalize">
              {{ entity.cardHolderJobName }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon icon="PhoneIcon" class="mr-75" />
              <span class="font-weight-bold">{{ $t('Telephone') }}</span>
            </th>
            <td class="pb-50">
              <span
                v-for="(phone, index) in entity.cardHolderTelephones"
                :key="phone.id"
              >
                <span v-if="index != 0">, </span>
                <span>
                  {{ phone.telephoneNumber }}
                </span>
              </span>
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon icon="MailIcon" class="mr-75" />
              <span class="font-weight-bold">{{ $t('email') }}</span>
            </th>
            <td class="pb-50 text-capitalize">
              {{ entity.emailAddress }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon icon="LayersIcon" class="mr-75" />
              <span class="font-weight-bold">{{
                $t('Card Holder Category Edit')
              }}</span>
            </th>
            <td class="pb-50">
              <span
                v-for="(category, index) in entity.cardHolderCategories"
                :key="category.id"
              >
                <span v-if="index != 0">, </span>
                <span>{{ category.name }}</span>
              </span>
            </td>
          </tr>
          <tr>
            <th>
              <feather-icon icon="CodeIcon" class="mr-75" />
              <span class="font-weight-bold">{{ $t('Employee Code') }}</span>
            </th>
            <td>
              {{ entity.cardHolderEmployeeCode }}
            </td>
          </tr>
        </table>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { BCard, BButton, BAvatar, BRow, BCol } from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BCard,
    BButton,
    BRow,
    BCol,
    BAvatar,
  },
  props: {
    entity: {
      type: Object,
      required: true,
    },
    endpoint: {
      type: String,
      required: true,
    },
    editPath: {
      type: String,
      required: true,
    },
  },
  setup() {
    return {
      avatarText,
    }
  },
  methods: {
    handleDeleteClick() {
      this.$store
        .dispatch(`${this.endpoint}/deleteEntity`, this.entity.id)
        .then(() => {
          // eslint-disable-next-line no-use-before-define
          this.refetchData()
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title:
                'The entity could not be deleted! Check the foreign key constraints first.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
  },
}
</script>
